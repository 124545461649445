<template>
    <section class="content">
        <table class="table table-hover" ref="tblkelas">
        <thead>
            <tr>
            <th>PROGRAM</th>
			<th>DESKRIPSI</th>
			<th>TINDAKAN</th>
            </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
        </table>
    </section>
    <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    name: "",
    data() {
        return {
        errors: [],
        method: "",
        roles: "",
        formTitle: "Tambah",
        form: {
            kelas: "",
        },
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
    },
    methods: {
        handleClick(e) {
        if (e.target.matches(".link-role")) {
            this.$router.push({ path: "/permit/" + e.target.dataset.id });
            return false;
        }
        if (e.target.closest("button")) {
            var btnEl = $(e.target).closest("button").get(0);
            if (btnEl.dataset.action == "detail") {
                var id = btnEl.dataset.id;
                if (id == 27) {
                    this.$router.push('/rekap-ujian/mapel/'+0+'/'+id);
                } else if (id == 28) {
                    this.$router.push('/rekap-ujian/mapel/'+0+'/'+id);
                } else {
                    this.$router.push('/rekap-ujian/kelas/'+id);
                }
            }
            return false;
        }
        },
    },
    mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblkelas, {
        title: "",
        roles: ["read"],
        ajax: "/akademik/ujian/per_program",
        columns: [
			{ data: "name" },
			{ data: "deskripsi" },
            { data: "action",
            render: function (data, type, row, meta) {
                return `<div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-info" data-action="detail" data-id="` + row.id +`"><i class="fas fa-eye"></i> Detail Kelas</button>
                </div>`
            } },
        ],
        // filterBy: [0],
        filter: false,
        rowCallback: function (row, data) {
        },
    });
},
};
</script>
